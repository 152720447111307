import Error from "next/error";
import { useEffect } from "react";
import * as Sentry from '@sentry/nextjs';
import { useRouter } from "next/router";
import { Typography } from "@material-ui/core";
import { createUseStyles } from "react-jss";
import Link from "next/link";

const useStyles = createUseStyles({
    container: {
        minHeight: "100vh",
        background: "rgb(255,255,255)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
    messageWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    statusCode: {
        margin: "2px",
    },
    divider: {
        margin: "2px",
        fontSize: "32px",

    },
    message: {
        margin: "2px",

    },
})
function Page({ statusCode }) {
    const router = useRouter();
    const classes = useStyles();

    const getURLWQueryParams = (route: string) => {
        const redirectBaseUrl = "https://app.stayqrious.com"
        const query = router.query;
        let queryString = "";
        if (query) {
            Object.keys(query).forEach((key, index) => {
                queryString += `${key}=${query[key]}`;
                if (index !== 0 && index !== Object.keys(query).length - 1) queryString += "&";
            })
        }
        const redirectedUrl = `${redirectBaseUrl}${route}${queryString ? "?" + queryString : ""}`
        return redirectedUrl;
    }

    const handleRedirects = () => {
        const pathname = window.location.pathname.split("/")[1];
        switch (pathname) {
            case "login":
            case "register":
            case "activity":
                window.location.replace(getURLWQueryParams(window.location.pathname));
                return
            case "webinar":
            case "masterclass":
                window.location.replace(getURLWQueryParams('/masterclass'));
                return
            default:
                Sentry.captureMessage(`${statusCode} ${router.asPath}`);
                return;
        }
    }

    useEffect(() => {
        if (statusCode === 404) {
            handleRedirects();
        } else {
            Sentry.captureMessage(statusCode);
        }
    }, [])

    return (
        <div className={classes.container}>
            <div className={classes.messageWrapper}>
                <div className={classes.statusCode}>
                    <Typography
                        variant="h4"
                    >
                        {statusCode}
                    </Typography>
                </div>
                <div className={classes.divider}>|</div>
                <div className={classes.message}>
                    <Typography
                        variant="h5"
                    >
                        {
                            statusCode === 404
                                ?
                                "Page not found"
                                : "Something went wrong"
                        }
                    </Typography>
                </div>
            </div>
            <div>
                <Link
                    href="/"
                >
                    Home - StayQrious
                </Link>
            </div>
        </div>
    )
}

Page.getInitialProps = ({ res, err }) => {
    const statusCode = res ? res.statusCode : err ? err.statusCode : 404
    return { statusCode }
}

export default Page